@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import "./vars.scss";

body {
  font-family: "Lato", sans-serif;
  background-color: $bg-color;
  color: $font-color;
}

p {
  margin: 0;
}
