@import "../../vars.scss";

@media (min-width: 1140px) {
  .containerItemLists {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
}

@media (min-width: 800px) and (max-width: 1139px) {
  .containerItemLists {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}

.containerItemLists {
  display: grid;
}

.buttonConsult {
  border-radius: 10px;
  padding: 5px;
  color: $font-color;
  background-color: $success;
  border: none;
  outline: none;

  &:hover {
    background-color: $success-hover;
    cursor: pointer;
  }
  &:active {
    background-color: $process;
    transform: scale(1.3);
    // transform: translateY(4px);
  }
}

.listTitle {
  font-size: 18px;
  font-weight: 700;
  color: $titles;
  margin-top: 30px;
  margin-bottom: 10px;
}

.containerList {
  display: grid;
  grid-row-gap: 10px;
  height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar-button:increment,
  .containerList::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $borders;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
}
